import React from "react";
import Navigation from "./Navigation";
import Banner from "./Banner";
import Header from "./Header";

const Home = () => {

  return <div>

            <Header />
            <Banner isAthlete={false} />

        </div>
};

export default Home;
